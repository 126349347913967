/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import { Avatar, Modal, Timeline } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import RichTextEditor from 'react-rte';
import ReactHtmlParser from 'react-html-parser';
import NoteComposer from './Editor';
import { TimeLinePointerIconWrap } from '../../../ui-elements/ui-elements-styled';
import { SwShieldCheck, SwUsers, SwRocket } from '../../../../components/utilities/icons';
import { Button } from '../../../../components/buttons/buttons';
import { addCollectionConversation, getCollectionConversation } from '../../../../redux/collections/service';

function CollectionActivity() {
  const [postModalVisible, setPostModalVisible] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [richText, setRichText] = useState(RichTextEditor.createEmptyValue());
  const currentUser = useSelector((state) => state.currentUser.data);

  const { reportDetails } = useSelector((state) => state.complaint);
  useEffect(() => {
    getCollectionConversation(reportDetails._id).then((res) => {
      setActivities(res?.data?.complaints || []);
    });
  }, [reportDetails._id]);
  const postActivity = () => {
    setPostLoading(true);
    const data = {
      note: richText.toString('html'),
      messageType: 'text',
      userType: 'partner',
    };
    addCollectionConversation(reportDetails.collectionDetails?._id, data)
      .then((res) => {
        activities?.notes?.unshift(res?.data?.data);
        setPostModalVisible(false);
        setRichText(RichTextEditor.createEmptyValue());
      })
      .finally(() => {
        setPostLoading(false);
      });
  };
  const getAvatar = (role) => {
    if (role === 'admin') {
      return <SwShieldCheck size={20} color="#2C99FF" />;
    }
    if (role === 'carrier') {
      return <SwUsers size={20} color="#20c997" />;
    }
    if (role === 'partner') {
      return <SwUsers size={20} color="#ff4d4f" />;
    }
  };
  const renderTimelineItem = (activity) => {
    return (
      <Timeline.Item
        className={activity.userType === 'admin' ? 'info' : activity.userType === 'carrier' ? 'success' : 'danger'}
        dot={getAvatar(activity.userType)}
      >
        <strong
          style={{
            fontSize: '1.1rem',
          }}
        >
          {activity.user === currentUser?._id
            ? 'You'
            : activity.userType === 'admin'
            ? 'Admin'
            : activity?.userType === 'partner'
            ? activities?.partner?.name
            : activity?.userType === 'carrier'
            ? reportDetails?.carrierDetails?.name
            : ''}
        </strong>{' '}
        <span
          style={{
            fontSize: '0.9rem',
          }}
        >
          added a note
        </span>
        <p
          style={{
            color: '#bbb',
            fontSize: '0.9rem',
          }}
        >
          {dayjs(activity.created_at).format('MMM D, YYYY [at] h:mA')}
        </p>
        <p>{ReactHtmlParser(activity.note)}</p>
      </Timeline.Item>
    );
  };
  return (
    <div
      style={{
        padding: '0 1rem',
      }}
    >
      <div
        style={{
          padding: '0 0 1rem 5px',
          borderBottom: '1px solid #eee',
          marginBottom: '1rem',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => setPostModalVisible(true)}
      >
        <Avatar size="large" style={{ backgroundColor: '#DDF7F0' }} icon={getAvatar('carrier')} />
        <div
          style={{
            marginLeft: '1rem',
            fontSize: '1.1rem',
            color: '#aaa',
            flexGrow: 1,
          }}
        >
          Post an update
        </div>
        <div>
          <SwRocket />
        </div>
      </div>
      <TimeLinePointerIconWrap>
        <Timeline>{activities?.notes?.map((activity) => renderTimelineItem(activity))}</Timeline>
      </TimeLinePointerIconWrap>

      <Modal
        bodyStyle={{
          padding: 0,
        }}
        closable={false}
        width={1000}
        onCancel={() => setPostModalVisible(false)}
        open={postModalVisible}
        footer={null}
      >
        <NoteComposer onChange={setRichText} text={richText} />
        <div style={{ padding: '0.5rem 1rem', display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
          <Button
            onClick={() => {
              setPostModalVisible(false);
              setRichText(RichTextEditor.createEmptyValue());
            }}
            size="default"
            type="primary"
            transparented
          >
            Cancel
          </Button>
          <Button loading={postLoading} onClick={postActivity} size="default" type="primary">
            Post
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default CollectionActivity;
