/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';

function CarrierDetails({ reportDetails }) {
  return (
    <>
      <div className="project-details">
        <Row gutter={10}>
          {[
            {
              id: 1,
              name: 'Carrier name',
              data: reportDetails?.carrierDetails?.legalName,
            },
            {
              id: 2,
              name: 'Carrier email',
              data: reportDetails?.carrierDetails?.email,
            },
            {
              id: 3,
              name: 'Driver Count',
              data: reportDetails?.carrierDetails?.driver_count,
            },
            {
              id: 4,
              name: 'Truck Count',
              data: reportDetails?.carrierDetails?.power_units_count,
            },
            {
              id: 5,
              name: 'MC MX Number',
              data: reportDetails?.carrierDetails?.mc_mx_number,
            },
            {
              id: 6,
              name: 'Name',
              data: reportDetails?.carrierDetails?.name,
            },
            {
              id: 7,
              name: 'Phone',
              data: reportDetails?.carrierDetails?.phone,
            },
            {
              id: 8,
              name: 'Physical Address',
              data: reportDetails?.carrierDetails?.physicalAddress,
            },
            {
              id: 9,
              name: 'Mailing Address',
              data: reportDetails?.carrierDetails?.mailingAddress,
            },
          ]?.map((i) => {
            return (
              <Col xs={12}>
                <div className="project-details__list">
                  <span>{i?.name}</span>
                  <p>{i?.data}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </>
  );
}

CarrierDetails.propTypes = {
  reportDetails: PropTypes.object,
};

export default CarrierDetails;
