import { DataService } from '../../config/dataService/dataService';

const getCollectionConversation = async (complaintId) => {
  const response = await DataService.get(`/collection/getCollection/activity/${complaintId}`);
  return response;
};
const addCollectionConversation = async (id, body) => {
  const response = await DataService.post(`/collection/complaints/${id}/collection/note/`, body);
  return response;
};

export { getCollectionConversation, addCollectionConversation };
